exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-member-js": () => import("./../../../src/pages/member.js" /* webpackChunkName: "component---src-pages-member-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-contributor-js": () => import("./../../../src/templates/contributor.js" /* webpackChunkName: "component---src-templates-contributor-js" */),
  "component---src-templates-contributors-list-js": () => import("./../../../src/templates/contributorsList.js" /* webpackChunkName: "component---src-templates-contributors-list-js" */),
  "component---src-templates-features-js": () => import("./../../../src/templates/features.js" /* webpackChunkName: "component---src-templates-features-js" */),
  "component---src-templates-headings-js": () => import("./../../../src/templates/headings.js" /* webpackChunkName: "component---src-templates-headings-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-leaf-js": () => import("./../../../src/templates/leaf.js" /* webpackChunkName: "component---src-templates-leaf-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */),
  "slice---src-components-pagenation-js": () => import("./../../../src/components/pagenation.js" /* webpackChunkName: "slice---src-components-pagenation-js" */)
}

